// Navbar
const navbar = document.getElementById("navbar");
const navbarMenu = document.getElementById("navbar-menu");

function menuNavbar() {
    navbar.classList.toggle("open");
    navbarMenu.classList.toggle("open");
}

navbarMenu.addEventListener('click', () => {
    menuNavbar();
})

addEventListener("scroll", () => {
    adaptMenu()
})

addEventListener("DOMContentLoaded", () => {
    adaptMenu()
})


function adaptMenu() {
    if (document.body.getBoundingClientRect().y < - (innerHeight - 75)) {
        navbarMenu.classList.add("white");
    } else {
        navbarMenu.classList.remove("white");
    }
}

$(document).ready(function () {
    $('#apartments-submenu-bottom').hide();
    $('#apartments-submenu').mouseover(function () {
        $('#apartments-submenu-bottom').show();
    });
    $('#apartments-submenu-bottom').mouseover(function () {
        $('#apartments-submenu-bottom').show();
    });
    $('#apartments-submenu').mouseout(function () {
        if ($('#apartments-submenu').is(':hover')) {
            $('#apartments-submenu-bottom').show();
        } else {
            $('#apartments-submenu-bottom').hide();
        }
    });
    $('#apartments-submenu-bottom').mouseout(function () {
        $('#apartments-submenu-bottom').hide();
    });

});

$(document).ready(function () {
    $('#apartments2-submenu-bottom').hide();
    $('#apartments2-submenu').mouseover(function () {
        $('#apartments2-submenu-bottom').show();
    });
    $('#apartments2-submenu-bottom').mouseover(function () {
        $('#apartments2-submenu-bottom').show();
    });
    $('#apartments2-submenu').mouseout(function () {
        if ($('#apartments2-submenu').is(':hover')) {
            $('#apartments2-submenu-bottom').show();
        } else {
            $('#apartments2-submenu-bottom').hide();
        }
    });
    $('#apartments2-submenu-bottom').mouseout(function () {
        $('#apartments2-submenu-bottom').hide();
    });

});

let lastScrollTop = 0;

addEventListener("scroll", (e) => {
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > lastScrollTop) {
       // downscroll code
       navbarMenu.style.top = - navbarMenu.getBoundingClientRect().height + "px";
    } else if (st < lastScrollTop) {
       // upscroll code
       navbarMenu.style.top = "0px";
    } // else was horizontal scroll
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
});