/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

require('./js/amountBed');
require('./js/amountPeople');
require('./js/aosAnimate');
require('./js/button404');
require('./js/carouselAutoplay');
require('./js/currentDate');
require('./js/emailAnimation');
require('./js/navbarToggle');
require('./js/owlCarousel');
require('./js/pagination');
require('./js/popup');
require('./js/showToggle');
require('./js/slowTravel');
require('./js/zoomImage');
