// Transition lors d'un click sur un bouton

// Bougies parfumées
$('a[href^="#bougies-parfumees"]').click(function () {
    var id_bougiesParfumees = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_bougiesParfumees).offset().top - 200
    }, 'slow');
    return false;
});

// Produits Corses
$('a[href^="#produits-corses"]').click(function () {
    var id_produitsCorses = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_produitsCorses).offset().top - 200
    }, 'slow');
    return false;
});

// Restaurants sur place
$('a[href^="#restaurants-sur-place"]').click(function () {
    var id_restaurantsSurPlace = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_restaurantsSurPlace).offset().top - 200
    }, 'slow');
    return false;
});

// Restaurants en livraison
$('a[href^="#restaurant-en-livraison"]').click(function () {
    var id_restaurantsEnLivraison = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_restaurantsEnLivraison).offset().top - 200
    }, 'slow');
    return false;
});

// Sites de Balagne
$('a[href^="#sites-de-balagne"]').click(function () {
    var id_sitesDeBalagne = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_sitesDeBalagne).offset().top - 200
    }, 'slow');
    return false;
});

// Activites nautiques
$('a[href^="#activites-nautiques"]').click(function () {
    var id_activitesNautiques = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_activitesNautiques).offset().top - 200
    }, 'slow');
    return false;
});

// Plages de Balagne
$('a[href^="#plages-de-balagne"]').click(function () {
    var id_plagesDeBalagne = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_plagesDeBalagne).offset().top - 200
    }, 'slow');
    return false;
});

// BackToTop
$(window).scroll(function () {
    if ($(document).scrollTop() > 350) {
        $(".backTotop").addClass('active');
    } else {
        $(".backTotop").removeClass('active');
    }
});

$('a[href^="#backTotop"]').click(function () {
    var the_id = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(the_id).offset().top - 200
    }, 'slow');
    return false;
});

// Pagination Précédente
$('a[href^="javascript:previous();"]').click(function () {
    var id_plagesDeBalagne = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_plagesDeBalagne).offset().top - 200
    }, 'slow');
    return false;
});

// Pagination Suivante
$('a[href^="javascript:next();"]').click(function () {
    var id_plagesDeBalagne = $(this).attr("href");
    $('html, body').animate({
        scrollTop: $(id_plagesDeBalagne).offset().top - 200
    }, 'slow');
    return false;
});