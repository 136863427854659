function initializeSlider(indicators = true) {
    const __ms = document.querySelector('.micro-slider');
    const __msSlider = new MicroSlider(__ms, { indicators: indicators, indicatorText: '' });
    const hammer = new Hammer(__ms);
    const __msTimer = 2000;
    let __msAutoplay = setInterval(() => __msSlider.next(), __msTimer);

    function handleMouseEnterLeave(e) {
        clearInterval(__msAutoplay);
        if (e.type === 'mouseleave') {
            __msAutoplay = setInterval(() => __msSlider.next(), __msTimer);
        }
        console.log(e.type + ' mouse detected');
    }

    // Détecter l'événement d'entrée de la souris
    __ms.onmouseenter = handleMouseEnterLeave;

    // Détecter l'événement de sortie de la souris
    __ms.onmouseleave = handleMouseEnterLeave;

    // Détecter l'événement de clic de la souris
    __ms.onclick = function (e) {
        clearInterval(__msAutoplay);
        console.log(e.type + ' mouse detected');
    }

    // Détecter un événement de tapotement gestuel avec la bibliothèque HammerJS
    hammer.on('tap', function (e) {
        clearInterval(__msAutoplay);
        console.log(e.type + ' gesture detected');
    });

    // Détecter un événement de glissement de geste avec la bibliothèque HammerJS
    hammer.on('swipe', function (e) {
        clearInterval(__msAutoplay);
        __msAutoplay = setInterval(() => __msSlider.next(), __msTimer);
        console.log(e.type + ' gesture detected');
    });

    let slideLink = document.querySelectorAll('.slider-item');
    if (slideLink && slideLink !== null && slideLink.length > 0) {
        slideLink.forEach(el => el.addEventListener('click', e => {
            e.preventDefault();
            let href = el.dataset.href;
            let target = el.dataset.target;
            // if (href !== '#') window.open(href, target);
        }));
    }
}

document.addEventListener('DOMContentLoaded', () => {
    if (location.href.includes("decouvrez-la-region")) {
        initializeSlider(true);
    }

    if (location.href.includes("villa")) {
        initializeSlider(false);
    }
});
