// Quantité pour les pré-reservations | Lits
const roomBtn = document.querySelector("#rooms-input-btn"),
	roomOptions = document.querySelector("#rooms-input-options"),

	bedsSubsBtn = document.querySelector("#beds-subs-btn"),
	bedsAddBtn = document.querySelector("#beds-add-btn"),

	bedsCountEl = document.querySelector("#rooms-count-beds");

let maxNumRooms = 4,
	isRoomInputOpen = false,
	bedsCount = 1;


if (roomBtn) {
	updateValues2();
	roomBtn.addEventListener('click', function (e) {
		if (isRoomInputOpen) {
			roomBtn.classList.remove("open");
			roomOptions.classList.remove("open");
		}
		else {
			roomBtn.classList.add("open");
			roomOptions.classList.add("open");
		}
		isRoomInputOpen = isRoomInputOpen ? false : true;
		e.preventDefault();
	});
}

if (bedsAddBtn && roomBtn) {
	bedsAddBtn.addEventListener('click', function () { bedsCount = addValues2(bedsCount); updateValues2(); });
	bedsSubsBtn.addEventListener('click', function () { bedsCount = substractValues2(bedsCount, 1); updateValues2(); });
}

function calcTotalRooms() { return bedsCount; }

function addValues2(count) { return (calcTotalRooms() < maxNumRooms) ? count + 1 : count; }

function substractValues2(count, min) { return (count > min) ? count - 1 : count; }

function updateValues2() {
	let btnText = `${bedsCount} lits`;
	roomBtn.innerHTML = btnText;
	bedsCountEl.innerHTML = bedsCount;

	if (bedsCount == 1) { bedsSubsBtn.classList.add("disabled"); }
	else { bedsSubsBtn.classList.remove("disabled"); }

	if (calcTotalRooms() == maxNumRooms) {
		bedsAddBtn.classList.add("disabled");
	}
	else {
		bedsAddBtn.classList.remove("disabled");
	}
}