// Carousel super simple vraiment chef - OWL Carousel
$(function () {
    var owl = $(".owl-carousel");
    owl.owlCarousel({
        items: 1,
        margin: 110,
        loop: true,
        nav: true,
        autoplayHoverPause: false,
        animateIn: "fadeIn",
        animateOut: "fadeOut",
        responsiveClass:true,
        responsive:{
            0:{
                center: true
            },
            600:{
                center: true
            }
        },
        navText: [
            "<i class='fa fa-chevron-left nav-left'></i>",
            "<i class='fa fa-chevron-right nav-right'></i>"
        ]
    });
});

$('.owl-nav > div').on('mouseover', function () {
    $('.owl-carousel').trigger('stop.owl.autoplay');

    //simple one (EDIT: not enough to make it work after testing it):
    //$('.owl-carousel').trigger('changeOption.owl.carousel', { autoplay: false });

    //or more complicated (will work for one carousel only, or else use 'each'):
    //EDIT: this one seems to work
    var carousel = $('.owl-carousel').data('owl.carousel');
    carousel.settings.autoplay = false; //don't know if both are necessary
    carousel.options.autoplay = false;
    $('.owl-carousel').trigger('refresh.owl.carousel');
});