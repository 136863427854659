// Date actuelle
"use strict";

if (document.getElementById("arrive")) {
    function CurrentDate() {
        var d = new Date();

        var days = [
            "dim.", // Dimanche
            "lun.", // Lundi
            "mar.", // Mardi
            "mer.", // Mercredi
            "jeu.", // Jeudi
            "ven.", // Vendredi
            "sam."  // Samedi
        ];

        var months = [
            "01", // Janvier
            "02", // Février
            "03", // Mars
            "04", // Avril
            "05", // Mai
            "06", // Juin
            "07", // Juillet
            "08", // Août
            "09", // Septembre
            "10", // Octobre
            "11", // Novembre
            "12"  // Décembre
        ];

        document.getElementById("arrive").innerHTML = days[d.getDay()] + " " + d.getDate() + "/" + months[d.getMonth()];
    }

    new CurrentDate();
    setInterval("CurrentDate()", 1000);
}